//@ts-check

import { useContext, useEffect } from "react";
import { CurrentUserContext } from "../../hooks/CurrentUserContext";
import { useRefresh } from "../../api/user/use-user";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../core/PathConstants";

export default function Refresh({ setIsLoading }) {
  const { onChangeUser } = useContext(CurrentUserContext);

  const refresh = useRefresh();

  const history = useHistory();

  useEffect(() => {
    const userEmail = localStorage.getItem("user");
    const token = localStorage.getItem("token");

    if (userEmail && token) {
      const data = {
        email: userEmail,
        refreshToken: token,
      };
      refresh.mutate(data, {
        onSuccess: (result) => {
          onChangeUser(result);
          setIsLoading(false);
        },
        onError: () => {
          history.push(AppRoutes.Login);
          setIsLoading(false);
        },
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  return null;
}
