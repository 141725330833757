import React, { useState, lazy } from "react";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import "./custom.css";
import Layout from "./components/Layout";
import AuthRoute from "./components/auth/AuthRoute";
import AnonymousRoute from "./components/auth/AnonymousRoute";
import { CurrentUserContext } from "./hooks/CurrentUserContext";
import { ApiProvider } from "./api/common";
import { COLORS } from "./components/core/constants/styles";
//import CacheBuster from "react-cache-buster";
//import packageFile from "../package.json";
//import { Loading } from "./components/core/Loading";
import { Switch } from "react-router";
import Refresh from "./components/auth/Refresh";
import { AppRoutes } from "./components/core/PathConstants";

const Login = lazy(() => import('./components/auth/Login'));
const VerifyCode = lazy(() => import('./components/auth/VerifyCode'));
const ForgotPassword = lazy(() => import('./components/auth/ForgotPassword'));
const SignUp = lazy(() => import('./components/auth/SignUp'));
const Company = lazy(() => import('./components/company-settings/Company'));
const ChangePassword = lazy(() => import('./components/auth/ChangePassword'));
const Loans = lazy(() => import('./components/buyers/Buyers'));
const BuyerSavedProperties = lazy(() => import('./components/lender/buyers/BuyerSavedProperties'));
const Subscriptions = lazy(() => import('./components/auth/SubscriptionPlans/Subscriptions'));
const BasicPlan = lazy(() => import('./components/auth/SubscriptionPlans/BasicPlan'));
const PremiumPlan = lazy(() => import('./components/auth/SubscriptionPlans/PremiumPlan'));
const EnterprisePlan = lazy(() => import('./components/auth/SubscriptionPlans/EnterprisePlan'));
const PartnerProfile = lazy(() => import('./components/profile/PartnerProfile'));
const ChangePasswordFromProfile = lazy(() => import('./components/profile/change-password/ChangePasswordFromProfile'));
const LoanEditor = lazy(() => import('./components/loans/LoanEditor'));
const LoanUploader = lazy(() => import('./components/loans/loan-uploader/LoanUploader'));
const UploadedLoanEditor = lazy(() => import('./components/loans/UploadedLoanEditor'));
const Error404 = lazy(() => import('./components/layout/Error404'));

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
  },
});

export default function App() {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const onChangeUser = (u) => {
    setUser(u);
    if (u?.success && u?.subscription) {
      localStorage.setItem("user", u.email);
      localStorage.setItem("token", u.refreshToken);
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
  };

  //const isProduction = process.env.NODE_ENV === "production";

  //const { version } = packageFile;

  const render = () => {
    return (
      <Layout>
        <Switch>
          <AuthRoute exact path={AppRoutes.Home}>
            <Loans />
          </AuthRoute>

          <AnonymousRoute exact path={AppRoutes.Login}>
            <Login />
          </AnonymousRoute>

          <AnonymousRoute exact path={AppRoutes.ForgotPasswprd}>
            <ForgotPassword />
          </AnonymousRoute>

          <AnonymousRoute exact path={AppRoutes.ChangePassword}>
            <ChangePassword />
          </AnonymousRoute>

          <AnonymousRoute exact path={AppRoutes.VerifyOTP}>
            <VerifyCode isForChangePassword={false} />
          </AnonymousRoute>

          <AnonymousRoute exact path={AppRoutes.VerifyOTPToResetPwd}>
            <VerifyCode isForChangePassword={true} />
          </AnonymousRoute>

          <AnonymousRoute exact path={AppRoutes.SignUp}>
            <SignUp />
          </AnonymousRoute>

          <AnonymousRoute exact path={AppRoutes.SubscriptionPlans}>
            <Subscriptions />
          </AnonymousRoute>

          <AnonymousRoute exact path={AppRoutes.BasicPlan}>
            <BasicPlan />
          </AnonymousRoute>

          <AnonymousRoute exact path={AppRoutes.PremiumPlan}>
            <PremiumPlan />
          </AnonymousRoute>

          <AnonymousRoute exact path={AppRoutes.EnterprisePlan}>
            <EnterprisePlan />
          </AnonymousRoute>

          <AuthRoute exact user={user} path={AppRoutes.CompanyConfig}>
            <Company />
          </AuthRoute>

          <AuthRoute exact user={user} path={AppRoutes.Profile}>
            <PartnerProfile />
          </AuthRoute>

          <AuthRoute
            exact
            user={user}
            path={AppRoutes.ChangeResetPasswordFromProfile}
          >
            <ChangePasswordFromProfile />
          </AuthRoute>

          <AuthRoute exact user={user} path={AppRoutes.BuyersAndLoans}>
            <Loans />
          </AuthRoute>

          <AuthRoute exact user={user} path={AppRoutes.BuyeSavedProperties}>
            <BuyerSavedProperties />
          </AuthRoute>

          <AuthRoute exact user={user} path={AppRoutes.CreateLoan}>
            <LoanEditor />
          </AuthRoute>

          <AuthRoute exact user={user} path={AppRoutes.EditLoan}>
            <LoanEditor />
          </AuthRoute>

          <AuthRoute exact user={user} path={AppRoutes.UploadLoan}>
            <LoanUploader />
          </AuthRoute>

          <AuthRoute exact user={user} path={AppRoutes.EditUploadedLoan}>
            <UploadedLoanEditor />
          </AuthRoute>
          <AuthRoute exact user={user} path={"*"}>
            <Error404 />
          </AuthRoute>
        </Switch>
      </Layout>
    );
  };

  return (
    /*<CacheBuster
      currentVersion={version}
      isEnabled={true} //isProduction
      isVerboseMode={false}
      loadingComponent={<Loading isLoading={isLoading} />}
      metaFileDirectory={"."}
    >*/
    <ApiProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CurrentUserContext.Provider value={{ user, onChangeUser }}>
          <Refresh setIsLoading={setIsLoading} />
          {!isLoading ? render() : <></>}
        </CurrentUserContext.Provider>
      </ThemeProvider>
    </ApiProvider>
    /*</CacheBuster>*/
  );
}
