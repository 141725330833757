import React, { useState, useContext } from "react";
import { AppBar, Container, Box, Alert } from "@mui/material";
import "./Layout.css";
import { Fragment } from "react";
import { CurrentUserContext } from "../hooks/CurrentUserContext";
import LayoutToolbar from "./layout/LayoutToolbar";
import MobileDrawer from "./layout/MobileDrawer";
import DesktopDrawer from "./layout/DesktopDrawer";

export default function Layout({ children }) {
  const { user } = useContext(CurrentUserContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <LayoutToolbar handleDrawerToggle={handleDrawerToggle} />
      </AppBar>
      {user?.success &&
        !user?.mustChangePassword &&
        user.subscription &&
        new Date(user.subscription?.billingDate) > new Date() && (
          <Fragment>
            <MobileDrawer
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />
            <DesktopDrawer />
          </Fragment>
        )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          padding: "72px 24px 24px 24px",
        }}
      >
        {user?.subscription && !user.subscription?.active ? (
          <Alert
            sx={{ marginBottom: "20px" }}
            severity="warning"
            variant="outlined"
          >
            Your subscription is not active, it will be valid until{" "}
            {new Date(user.subscription?.billingDate).toDateString()}. Please
            renew your subscription or contact us at{" "}
            <a href={`mailto:${"info@approvaliq.com"}`}>info@approvaliq.com</a>
          </Alert>
        ) : null}
        <Container maxWidth={"xl"} disableGutters>
          {children}
        </Container>
      </Box>
    </Box>
  );
}
